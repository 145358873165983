import { useEffect, useState } from 'react'
import CookieConsent, { Cookies } from 'react-cookie-consent'

import './styles.scss'

const Main = () => {
  return (
    <div className='Landing'>
      <div className='Landing__header flex v-center h-center'>
        <div className='general-wrapper flex between v-center'>
          <div className='Landing__header__logo'>
            <img src='assets/images/logo.svg' />
          </div>
          <div className='Landing__header__menu flex'>
            <div className='only-desktop'>
              <a href='#producto'>PRODUCTO</a>
            </div>
            <div className='only-desktop'>
              <a href='#ventajas'>VENTAJAS</a>
            </div>
            <div className='only-desktop'>
              <a href='#nosotros'>NOSOTROS</a>
            </div>
            <div>
              <a href='#contacto'>CONTACTO</a>
            </div>
          </div>
        </div>
      </div>
      <div className='Landing__section1 flex v-center h-center'>
        <div className='general-wrapper flex between'>
          <div className='Landing__section1__text flex column v-center h-center'>
            <div className='Landing__section1__title'>
              <span className='orange'>Llega una nueva era</span> <br />
              para la asistencia en carretera
            </div>
            <div>
              Nexus es una plataforma creada para optimizar los procesos de asistencia en carretera, reducir costes,
              simplificar la gestión, mejorar los tiempos de ejecución y automatizar tareas.
            </div>
          </div>
          <div className='Landing__section1__image'>
            <img src='assets/images/section1.png' />
          </div>
        </div>
      </div>
      <div className='Landing__section2 flex v-center h-center' id='producto'>
        <div className='general-wrapper flex between'>
          <div className='Landing__section2__image'>
            <img src='assets/images/section2.png' />
            <div>Web + App móvil + Widget</div>
          </div>
          <div className='Landing__section2__text flex column h-center'>
            <div className='Landing__section2__anchor'>PRODUCTO</div>
            <div className='Landing__section2__title'>
              La plataforma digital <br />
              <span className='orange'>más avanzada</span>
            </div>
            <div>
              Con nuestra plataforma en la nube y la aplicación del conductor podrás gestionar el ciclo de vida del
              servicio de principio a fin y mantener al asistido informado en tiempo real.
            </div>
            <div className='Landing__section2__list'>
              <div>
                <div className='flex v-center'>
                  <div className='Landing__section2__list__dot' />
                  Disponibilidad de toda la red de proveedores
                </div>
                <div className='flex v-center'>
                  <div className='Landing__section2__list__dot' />
                  Asignación del recurso más cercano al percance
                </div>
                <div className='flex v-center'>
                  <div className='Landing__section2__list__dot' />
                  Cálculo de costes automático al solicitar servicio
                </div>
              </div>
              <div>
                <div className='flex v-center'>
                  <div className='Landing__section2__list__dot' />
                  Seguimiento en tiempo real y widget de asistido
                </div>
                <div className='flex v-center'>
                  <div className='Landing__section2__list__dot' />
                  Ejecución más rápida, económica y eficiente
                </div>
                <div className='flex v-center'>
                  <div className='Landing__section2__list__dot' />
                  Reducción de trámites administrativos
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='Landing__section3 flex v-center h-center' id='ventajas'>
        <div className='general-wrapper'>
          <div className='Landing__section3__anchor'>VENTAJAS</div>
          <div className='Landing__section3__title'>
            Evoluciona tu negocio <span className='orange'>con Nexus</span>
          </div>
          <div className='Landing__section3__list flex between'>
            <div className='Landing__section3__item'>
              <div className='Landing__section3__item__icon'>
                <img src='assets/icons/bullet1.svg' />
              </div>
              <div className='Landing__section3__item__text'>
                <span className='bold'>Plataforma Saas </span> 100% en la nube
              </div>
            </div>
            <div className='Landing__section3__item'>
              <div className='Landing__section3__item__icon'>
                <img src='assets/icons/bullet2.svg' />
              </div>
              <div className='Landing__section3__item__text'>
                <span className='bold'>Asignación automática </span>
                <br /> de recursos
              </div>
            </div>
            <div className='Landing__section3__item'>
              <div className='Landing__section3__item__icon'>
                <img src='assets/icons/bullet3.svg' />
              </div>
              <div className='Landing__section3__item__text'>
                <span className='bold'>Mejora en el tiempo de llegada, </span>y ahorro de combustible
              </div>
            </div>
            <div className='Landing__section3__item'>
              <div className='Landing__section3__item__icon'>
                <img src='assets/icons/bullet4.svg' />
              </div>
              <div className='Landing__section3__item__text'>
                <span className='bold'>Simplificación </span> de los modelos de tarifas
              </div>
            </div>
            <div className='Landing__section3__item'>
              <div className='Landing__section3__item__icon'>
                <img src='assets/icons/bullet5.svg' />
              </div>
              <div className='Landing__section3__item__text'>
                <span className='bold'>Sistema integrado </span> de tickets de soporte
              </div>
            </div>
            <div className='Landing__section3__item'>
              <div className='Landing__section3__item__icon'>
                <img src='assets/icons/bullet6.svg' />
              </div>
              <div className='Landing__section3__item__text'>
                <span className='bold'>Completo parte de daños </span> digital
              </div>
            </div>
            <div className='Landing__section3__item'>
              <div className='Landing__section3__item__icon'>
                <img src='assets/icons/bullet7.svg' />
              </div>
              <div className='Landing__section3__item__text'>
                <span className='bold'>Seguimiento y valoración </span> por el asistido
              </div>
            </div>
            <div className='Landing__section3__item'>
              <div className='Landing__section3__item__icon'>
                <img src='assets/icons/bullet8.svg' />
              </div>
              <div className='Landing__section3__item__text'>
                <span className='bold'>Control de documentos, </span> caducidad y renovación
              </div>
            </div>
            <div className='Landing__section3__item'></div>
          </div>
        </div>
      </div>
      <div className='Landing__section4 flex v-center h-center' id='nosotros'>
        <div className='general-wrapper flex between'>
          <div className='Landing__section4__text flex column h-center'>
            <div className='Landing__section4__anchor'>NOSOTROS</div>
            <div className='Landing__section4__title'>
              Especialistas en software de gestión
              <span className='orange'> y plataformas de movilidad </span> <br />
            </div>
            <div>
              <p>
                Hemos aplicado las ventajas de las últimas tecnologías para ofrecer una plataforma de asistencia en
                carretera que simplifica y mejora los modelos tradicionales. Nexus se ha creado para mejorar la
                eficiencia, reducir costes operativos y dotar a los intervinientes de herramientas adaptadas a los
                nuevos tiempos.
              </p>
              <p>
                Invertimos constantemente en I+D+I para continuar ofreciendo mejoras y nuevas capacidades al sector.
              </p>
            </div>
          </div>
          <div className='Landing__section4__image'>
            <img src='assets/images/section4.jpg' />
            <div className='Landing__section4__image__text flex v-center'>
              Más de 20 años de experiencia en tecnología y asistencia en carretera
            </div>
          </div>
        </div>
      </div>
      <div className='Landing__footer flex v-center h-center' id='contacto'>
        <div className='general-wrapper flex between column'>
          <div className='Landing__footer__up flex between'>
            <div className='Landing__footer__col1'>
              <div className='Landing__footer__title'>Únete a la red que está cambiando la asistencia en carretera</div>
              <div className='Landing__footer__text'>
                Cada día más empresas y proveedores maximizan sus beneficios al unirse a Nexus, la plataforma
                tecnológica de asistencia más avanzada de Europa. Para más información, ponte en contacto con nosotros
                en <span className='bold'>info@nexus-365.com</span>
              </div>
            </div>
            <div className='Landing__footer__col2'>
              <div className='bold'>Información de contacto</div>
              <div className='Landing__footer__text'>info@nexus-365.com</div>
              <div className='Landing__footer__text'>Com. de Madrid</div>
              <div className='Landing__footer__text'>España</div>
            </div>
          </div>
          <div className='Landing__footer__legal flex end'>
            <div className='orange'>©2021 Nexus Inc.</div>
          </div>
        </div>
      </div>
      <CookieConsent buttonText='Entendido'>
        Esta web utiliza cookies para mejorar la experiencia del usuario.
      </CookieConsent>
    </div>
  )
}

export default Main

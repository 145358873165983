import { BrowserRouter, Switch, Route } from 'react-router-dom'
import ToastWrapper from './components/ToastWrapper'

import Main from './pages/Main'

import './styles/bootstrap.scss'
import './styles/base.scss'
import './styles/app.scss'
import './styles/responsive.scss'
import 'ui-components/dist/index.css'

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Switch>
          <Route path='/' component={Main} />
        </Switch>
      </BrowserRouter>

      <ToastWrapper />
    </div>
  )
}

export default App
